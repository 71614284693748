// List of fields needed to create an order.
// These will be the "headers" in the csv

// The order of the fields in the csv must match the order of the headers in this array since new fields can only come location id had to adjust it accordingly
export const positionedPhysicalCardHeader = [
  [
    'ExternalTag',
    '*First Name',
    '*Last Name',
    '*Email Address',
    'Phone Number',
    'Date of Birth',
    '*Address Line 1',
    'Address Line 2',
    '*City',
    '*State/Province',
    '*Postal Code',
    '*Country',
    '*ShippingMethod',
    'Load Amount',
    'Emboss Line 2',
    'SIN/SSN',
    'Shipping Address First Name',
    'Shipping Address Last Name',
    'Shipping Address Line 1',
    'Shipping Address Line 2',
    'Shipping Address City',
    'Shipping Address State',
    'Shipping Address Postal Code',
    'Shipping Address Country',
  ]
];

export const physicalCardHeader = [
  [
    ...positionedPhysicalCardHeader[0],
    'Office Id',
    'EmployerName',
    'Occupation',
    'NonResidentAlien',
    'PoliticallyExposedPerson',
    'PrimarySourceOfFunds',
    'PrimaryUseOfAccount',
    'CountryOfCitizenship'
  ]
];

export const galileoPhysicalCardHeader = [
  [
    ...positionedPhysicalCardHeader[0],
    'Location Id',
    'Office Id',
    'EmployerName',
    'Occupation',
    'NonResidentAlien',
    'PoliticallyExposedPerson',
    'PrimarySourceOfFunds',
    'PrimaryUseOfAccount',
    'CountryOfCitizenship'
  ]
];

export const virtualCardHeader = [
  [
    'ExternalTag',
    '*First Name',
    '*Last Name',
    '*Email Address',
    'Phone Number',
    'Date of Birth',
    '*Address Line 1',
    'Address Line 2',
    '*City',
    '*State/Province',
    '*Postal Code',
    '*Country',
    'Load Amount',
    'Emboss Line 2',
    'SIN/SSN',
    'Office Id',
    'EmployerName',
    'Occupation',
    'NonResidentAlien',
    'PoliticallyExposedPerson',
    'PrimarySourceOfFunds',
    'PrimaryUseOfAccount',
    'CountryOfCitizenship'
  ]
];
