import React, { useState } from 'react';
// Import Shared Components
import {
  Alert,
  Card,
  CardGroup,
  CSVButton,
  Loader,
  ClientErrorComponent,
  Input,
  Label,
  Button,
  Dropdown,
} from 'shared-components';

import { post } from '../../../utils/http';

// Import Component Styles
import { Header } from './styles';
// Import Utils
import { getCollectionsReportCsvData } from '../../../utils/csv';


// const formatOffsetRem = (rem) => {
//   if (rem < 10) {
//     return `0${rem}`;
//   }
//   return `${rem}`;
// };

// const formatOffsetDiv = (div) => {
//   if (div < 10) {
//     return `0${div}`;
//   }
//   return `${div}`;
// };

// Format utc offset from native
// const getUTCOffset = () => {
//   const offset = new Date().getTimezoneOffset();
  
//   const offsetDiv = (offset / 60 | 0); // Get native browser offset in minutes and convert to hours (whole number only)
//   const offsetRem = (offset % 60);
//   return offset <= 0 ? `:00+${formatOffsetDiv(offsetDiv)}:${formatOffsetRem(offsetRem)}` : `:00-${formatOffsetDiv(offsetDiv)}:${formatOffsetRem(offsetRem)}`;
// };

const Collections = () => {

  const [transactions, setTransactions] = useState(null);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const [datetimeFrom, setDatetimeFrom] = useState(null);
  const [datetimeTo, setDatetimeTo] = useState(null);

  const [error, setError] = useState(null);


  const [reportSelection, setReportSelection] = useState('all');

  // Function to convert 12-hour format to 24-hour format
  function convertTo24HourFormat(datetime) {
    const [time, period] = datetime.split(' '); // Split time and AM/PM
    const [hours, minutes] = time.split(':'); // Get hours and minutes
    let hours24 = parseInt(hours, 10);
  
    // Convert to 24-hour format
    if (period === 'PM' && hours24 < 12) {
      hours24 += 12; // Convert PM time to 24-hour format
    } else if (period === 'AM' && hours24 === 12) {
      hours24 = 0; // Convert 12 AM to 00 (midnight)
    }
  
    // Pad hours and minutes to ensure proper formatting
    const formattedTime = `${String(hours24).padStart(2, '0')}:${minutes}`;
    return datetime.replace(time, formattedTime); // Replace 12-hour time with 24-hour time
  }
  
  // Function to convert date to RFC3339 format with explicit timezone offset (e.g., +05:30)
  function formatDateWithOffset(datetimeinput) {
    let datetime = datetimeinput;
    // Check if the input is in 12-hour format (AM/PM)
    const is12HourFormat = /[APap][Mm]/.test(datetime); // If it has AM/PM
  
    // Convert to 24-hour format if it's in 12-hour format
    if (is12HourFormat) {
      datetime = convertTo24HourFormat(datetime);
    }
  
    const date = new Date(datetime);
    
    // Get the timezone offset in minutes (negative value for UTC+ time zones)
    const offsetMinutes = date.getTimezoneOffset();
    
    // Convert minutes offset to hours and minutes
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60); // Get the absolute value and divide by 60 to get hours
    const offsetMins = Math.abs(offsetMinutes) % 60; // Get the remaining minutes after extracting hours
  
    // Determine the sign of the offset: '+' if the offset is positive, '-' if negative
    const offsetSign = offsetMinutes > 0 ? '-' : '+'; 
  
    // Format the offset as `+hh:mm` or `-hh:mm`
    const formattedOffset = `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(offsetMins).padStart(2, '0')}`;
  
    return `${date.toISOString().slice(0, 19)}${formattedOffset}`;
  }

  const handleOnSubmit = async() => {
    // const UTCOffset = getUTCOffset();
    
    const body = {
      status: reportSelection === 'all' ? null : reportSelection,
      datetime_from: formatDateWithOffset(datetimeFrom), // datetimeFrom + UTCOffset,
      datetime_to: formatDateWithOffset(datetimeTo), // datetimeTo + UTCOffset,
    };
    
    setTransactions(null);
    setError(null);
    setIsError(false);
    setIsLoading(true);

    try {
      const data = await post('/api/v1/client_portal/collections/transaction_report', body);
      
      if (data.count === 0) {
        setError('No transactions found for this date range.');
        setIsError(false);
        setIsLoading(false);
      } else if (data.count) {
        setError(null);
        setIsError(false);
        setTransactions(data.data);
        setIsLoading(false);
      } else {
        setIsError(true);
        setIsLoading(false);
      }
    } catch(e) {
      setError(e.message);
      setIsLoading(false);
      setIsError(true);
    }
  };

  // Define transaction header labels to be used when converting data of type
  // Object to CSV.
  const headers = [
    { label: 'Collection Email', key: 'collection_email'},
    { label: 'Collection Account Reference', key: 'collection_account_ref'},
    { label: 'Collection External Tag', key: 'collection_external_tag'},
    { label: 'Collection Redemption Token', key: 'collection_redemption_token'},
    { label: 'Collection Name', key: 'collection_name'},
    { label: 'Transaction ID', key: 'transaction_id'},
    { label: 'Transaction Time', key: 'transaction_time'},
    { label: 'Transaction Status', key: 'transaction_status'},
    { label: 'Transaction Type', key: 'transaction_type'},
    { label: 'Transaction Amount', key: 'transaction_amount'},
    { label: 'Processor Transaction ID', key: 'processor_transaction_id'},
    { label: 'Processor Transaction Time', key: 'processor_transaction_time'},
    { label: 'Processor Result Message', key: 'processor_result_message'},
    { label: 'Authorization Code', key: 'authorization_code'},
    { label: 'AVS Response', key: 'avs_response'},
    { label: 'CVV Response', key: 'cvv_response'},
    { label: 'Card First Name', key: 'card_first_name'},
    { label: 'Card Last Name', key: 'card_last_name'},
    { label: 'Card Type', key: 'card_type'},
    { label: 'Card Last Four Digits', key: 'card_last_four'},
    { label: 'Card Expiry Date', key: 'card_exp_date'},
  ];

  const reportOptions = [
    { key: 'all', val: 'Daily Transaction Report'},
    { key: 'settled', val: 'Settlement Report' }
  ];

  return (
    <>
      {error && <Alert type="danger">{error}</Alert>}
      <CardGroup>
        <Header>
          <Card>
            <Dropdown
              selected={reportSelection}
              updateItem={value => setReportSelection(value)}
              options={reportOptions}
            />
            <Label htmlFor="datetime_from">
              DateTime Start:
            </Label>
            <Input
              id="datetime_from"
              type="datetime-local"
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
              placeholder="yyyy-mm-ddThh:mm"
              value={datetimeFrom}
              onChange={(e) => setDatetimeFrom(e.target.value)}
            />
            <Label htmlFor="datetime_from">
              DateTime End:
            </Label>
            <Input
              id="datetime_to"
              type="datetime-local"
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
              placeholder="yyyy-mm-ddThh:mm"
              value={datetimeTo}
              onChange={(e) => setDatetimeTo(e.target.value)}
            />
            <Button
              type="primary"
              size="large"
              value='Submit'
              onClick={handleOnSubmit}
            >
              Submit
            </Button>
          </Card>
        </Header>
        {isError && (
          <Card>
            <ClientErrorComponent />
          </Card>
        )}
        {isLoading && (
          <Card>
            <Loader />
          </Card>
        )}
        {transactions && (
          <Card>
            <CSVButton
              data={getCollectionsReportCsvData(transactions)}
              headers={headers}
              filename={`${reportSelection}-transactions-${new Date().toLocaleDateString()}`}
            >
              Export to CSV
            </CSVButton>
          </Card>
        )}
      </CardGroup>
    </>
  );
};

export default Collections;