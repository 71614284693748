import React from 'react';
// Import Shared Components
import {
  Title,
  Alert,
  Table,
  Card,
  CardGroup,
  Loader,
} from 'shared-components';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Common Hooks
import useTellerApi from '../../../hooks/useTellerApi';
import { useTimeZone } from '../../../hooks/useTimeZone';

export default ({ history }) => {
  const [
    {
      data: { data: programs },
      isLoading,
      isError,
    },
  ] = useTellerApi('/api/v1/client_portal/card_issuing/programs', {
    count: 0,
    data: [],
    limit: 100,
    offset: 0,
  });

  const [timeZone] = useTimeZone();

  if (isLoading) return <Loader />;
  if (isError) return <ClientErrorComponent data={{}} />;

  const columns = [
    {
      Header: 'Program ID',
      accessor: 'id',
    },
    {
      Header: 'Program Name',
      accessor: 'name',
    },
    {
      id: 'dda',
      Header: 'Direct Deposit Account',
      accessor: d =>
        d.processor_name === 'carta' || d.processor_name === 'e6'
          ? d.direct_deposit_account
            ? 'Enabled'
            : 'Disabled'
          : 'N/A',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Master Funding Account #',
      accessor: 'master_funding_account_number',
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      Cell: ({ value }) => formatTime(value, 'dateTime', timeZone),
    },
  ];

  return (
    <>
      <CardGroup>
        {programs.length > 0 ? (
          <Card>
            <Title>Program List</Title>
            <Table
              columns={columns}
              data={programs}
              /* eslint-disable no-unused-vars */
              getTrProps={(state, rowInfo, column) => {
                return {
                  style: {
                    cursor: 'pointer',
                  },
                  className: 'hoverable',
                  onClick: (e, handleOriginal) => {
                    history.push(
                      `/card-issuing/programs/${rowInfo.original.id}`
                    );
                  },
                };
              }}
              /* eslint-enable no-unused-vars */
            />
          </Card>
        ) : (
          <Alert type="info">
            Currently there are no Programs Available. Contact Berkeley Payment
            Solutions to learn more about Card Issuing Solutions.
          </Alert>
        )}
      </CardGroup>
    </>
  );
};
