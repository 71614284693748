import React, { useState } from 'react';
// Import Shared Components
import {
  InputGroup,
  Checkbox,
  SubmitButton,
  ActionBar,
  ButtonGroup,
} from 'shared-components';
// Import Component Styles
import {
  Row,
  FormGroup,
  FormSection,
  FormSectionTitle,
} from './styles';
// Import Helper Functions
import {
  convertCountryCode,
  convertCountryAbbrev
} from './helperFunctions'

import { put, get } from '../../../../utils/http';

const UpdateCardForm = ({
  card,
  setError,
  setCardholderDetailsList, 
  cardsWithAddress, 
  program,
  setSuccess,
  setClientError,
  setLoading,
  searchParams,
  getCardholderDetailsData,
}) => {

  const [phone, setPhone] = useState(card.phone);
  const [dateOfBirth, setDateOfBirth] = useState(card.date_of_birth);
  const [email, setEmail] = useState(card.email);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [isSameAsBilling, setIsSameAsBilling] = useState(false);
  // Billing Info
  const [firstNameBilling, setFirstNameBilling] = useState(card.first_name)
  const [lastNameBilling, setLastNameBilling] = useState(card.last_name)
  const [middleNameBilling, setMiddleNameBilling] = useState(card.middle_name)
  const [addressOneBilling, setAddressOneBilling] = useState(card.address1)
  const [addressTwoBilling, setAddressTwoBilling] = useState(card.address2)
  const [cityBilling, setCityBilling] = useState(card.city)
  const [provinceBilling, setProvinceBilling] = useState(card.state)
  const [countryBilling, setCountryBilling] = useState(convertCountryCode(card.country))
  const [postalCodeBilling, setPostalCodeBilling] = useState(card.postal_code)
  // Shipping Info
  const [firstNameShipping, setFirstNameShipping] = useState(card.shipping_address?.first_name)
  const [lastNameShipping, setLastNameShipping] = useState(card.shipping_address?.last_name)
  const [addressOneShipping, setAddressOneShipping] = useState(card.shipping_address?.address1)
  const [addressTwoShipping, setAddressTwoShipping] = useState(card.shipping_address?.address2)
  const [cityShipping, setCityShipping] = useState(card.shipping_address?.city)
  const [provinceShipping, setProvinceShipping] = useState(card.shipping_address?.state)
  const [countryShipping, setCountryShipping] = useState(convertCountryCode(card.shipping_address?.country))
  const [postalCodeShipping, setPostalCodeShipping] = useState(card.shipping_address?.postal_code)

  const isCanada = card.country === "124"
  const isVirtual = card.card_program_type === "virtual_reloadable" || card.card_program_type === "virtual_nonreloadable"
  const isCarta = program.processor_name === "carta" || program.processor_name === "e6"

  const onSubmit = async (e) => {
    e.preventDefault()
    setError(null)
    setSuccess(null)

    if (confirmEmail !== email) {
      setError("Emails must match")
      return
    }

    if (window.confirm("Are you sure you want to submit the form?")) {
      setLoading(true);
      let shipping_address = {}
  
      if (isSameAsBilling) {
        shipping_address = {
          address1: addressOneBilling,
          address2: addressTwoBilling,
          city: cityBilling,
          country: convertCountryAbbrev(countryBilling),
          first_name: firstNameBilling,
          last_name: lastNameBilling,
          postal_code: postalCodeBilling,
          state: provinceBilling
        }
      } else {
          shipping_address = {
            address1: addressOneShipping,
            address2: addressTwoShipping,
            city: cityShipping,
            country: convertCountryAbbrev(countryShipping),
            first_name: firstNameShipping,
            last_name: lastNameShipping,
            postal_code: postalCodeShipping,
            state: provinceShipping
          }
      }
  
      const body = {
        first_name: firstNameBilling,
        middle_name: middleNameBilling,
        last_name: lastNameBilling,
        date_of_birth: dateOfBirth,
        address1: addressOneBilling,
        address2: addressTwoBilling,
        city: cityBilling,
        state: provinceBilling,
        postal_code: postalCodeBilling,
        country: convertCountryAbbrev(countryBilling),
        phone: phone,
        email: email,
        shipping_address: isVirtual? undefined: shipping_address,
        shipping_method: isVirtual? undefined: card.shipping_method
      }
  
      try {
        const updatedCardholderDetails = await put(`/api/v1/client_portal/card_issuing/cardholders/${card.cardholder_id}`, body);
  
        let cards = []

        // Carta api are slow so cards wont have the most up to date info must make a subsequent reuest to grab the information
        if (isCarta) {
          const query = new URLSearchParams({
            ...searchParams,
          }).toString();

          const cardholderListData = await get(
            `/api/v1/client_portal/card_issuing/cardholders?${query}`
          )
          
          const cardholderList = cardholderListData.data

          const cardholderDetailsList = await getCardholderDetailsData(cardholderList);

          cards = cardsWithAddress(cardholderDetailsList, program)
        } else {
          cards = cardsWithAddress([updatedCardholderDetails], program)
        }
          
        setCardholderDetailsList(cards)
        setSuccess("Successfully updated card")
      } catch (error) {
        if (error.message === "Cardholder address was updated recently. Please wait and try again at a later date.") {
          try {
            const {cardholder_address_update_interval: addressUpdateInterval, updated_at: updatedAt} = await get(
              `/api/v1/client_portal/card_issuing/programs/${program.id}/fraud_control_config` 
              );

              //find the next time the card can be update
              const findUpdateTime = (addressUpdateInterval, updatedAt, addressLastUpdated) => {
                const requiredHours = addressUpdateInterval * 24;
                const requiredDuration = requiredHours * 60 * 60 * 1000;
                // use updatedAt if address has never been updated before
                let addressLastUpdate = addressLastUpdated || updatedAt;
                addressLastUpdate = new Date(addressLastUpdate).getTime();

                return new Date(Date.now() + (requiredDuration - (Date.now() - addressLastUpdate)));
              }

              const updateTime = findUpdateTime(addressUpdateInterval, updatedAt, card.address_last_updated)

            setError(`Cardholder address was updated recently. Please wait and try again on ${updateTime}.`)
            setLoading(false);
            return
          } catch (error) {
            setClientError(error.message)
            setLoading(false);
          }
        }
        setClientError(error.message)
      }
      setLoading(false);
      return
    }

    return

  }

  return (
    <>
      <FormGroup onSubmit={onSubmit}>
        <FormSection>
        <FormSectionTitle>Billing Information</FormSectionTitle>
          <Row>
            <InputGroup
              required
              label="First Name:"
              labelFor="firstNameBilling"
              dataId={"update-card-first-name-billing-input"}
              value={firstNameBilling}
              onChange={(e) => setFirstNameBilling(e.target.value)}
              pattern="^[A-Za-z\-.\s']{1,40}$"
              title="Input must contain 1-40 characters; letters, dashes, periods, spaces and apostrophes.
              only." 
            />
            <InputGroup
              label="Middle Name:"
              labelFor="middleNameBilling"
              dataId={"update-card-last-name-billing-input"}
              value={middleNameBilling}
              onChange={(e) => setMiddleNameBilling(e.target.value)}
              pattern="^[A-Za-z\-.\s']{1,40}$"
              title="Input must contain 1-40 characters; letters, dashes, periods, spaces and apostrophes.
              only."
            />
            <InputGroup
              required
              label="Last Name:"
              labelFor="lastNameBilling"
              dataId={"update-card-middle-name-billing-input"}
              value={lastNameBilling}
              onChange={(e) => setLastNameBilling(e.target.value)}
              pattern="^[A-Za-z\-.\s']{1,40}$"
              title="Input must contain 1-40 characters; letters, dashes, periods, spaces and apostrophes.
              only."
            />
          </Row>
          <Row>
            <InputGroup
              required
              label="Address line 1:"
              labelFor="addressOneBilling"
              dataId={"update-card-address-one-billing-input"}
              value={addressOneBilling}
              onChange={(e) => setAddressOneBilling(e.target.value)}
              pattern="^.{1,40}$"
              title="Maximum length of 40 characters. Cannot be a P.O. Box."
            />   
            <InputGroup
              label="Address line 2:"
              labelFor="addressTwoBilling"
              dataId={"update-card-address-two-billing-input"}
              value={addressTwoBilling}
              onChange={(e) => setAddressTwoBilling(e.target.value)}
              pattern="^.{1,30}$"
              title="Maximum length of 30 characters."
            />
          </Row>
          <Row>
            <InputGroup
              required
              label="City:"
              labelFor="cityBilling"
              dataId={"update-card-city-billing-input"}
              value={cityBilling}
              onChange={(e) => setCityBilling(e.target.value)}
              pattern="^[a-zA-Z ]{1,20}$"
              title="Letters and spaces, maximum length of 20 characters."
            />
            <InputGroup
              required
              label={isCanada? "Province:":"State:"}
              labelFor="provinceBilling"
              dataId={"update-card-province-billing-input"}
              value={provinceBilling}
              onChange={(e) => setProvinceBilling(e.target.value)}
              pattern={isCanada?"^(AB|BC|MB|NB|NL|NS|NT|NU|ON|PE|QC|SK|YT)$":"^(AL|AK|AZ|AR|CA|CO|CT|DE|DC|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY)$"}
              title="Valid 2 character state abbreviation."
            />
            <InputGroup
              required
              label="Country:"
              labelFor="countryBilling"
              dataId={"update-card-country-billing-input"}
              value={countryBilling}
              onChange={(e) => setCountryBilling(e.target.value)}
              pattern="^(CA|US)$"
              title="Valid 2 character country abbreviation."
            />
            <InputGroup
              required
              label={isCanada? "Postal Code:":"Zip Code:"}
              labelFor="postalCodeBilling"
              dataId={"update-card-postal-code-billing-input"}
              value={postalCodeBilling}
              onChange={(e) => setPostalCodeBilling(e.target.value)}
              pattern="^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)?$"
              title="Valid input examples: 12345 or 12345-1234 (US), M5V-2M7 or M5V2M7 (CA)."
            />
          </Row>
          <Row>
            <InputGroup
              required
              label="Email Address:"
              labelFor="emailAddress"
              dataId={"update-card-email-address-input"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              pattern="^\S+@\S+\.\S+$"
              title="Valid email address. Example: user@domain.com."
            />
            <InputGroup
              required
              label="Re-enter Email Address:"
              labelFor="confirmEmail"
              dataId={"update-card-confirm-email-input"}
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              title="Must match the email address"
            />
          </Row>
          <Row>
            <InputGroup
              required={isCarta?true:false}
              label="Phone:"
              labelFor="phone"
              dataId={"update-card-phone-input"}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              pattern="^\d{10}$"
              title="Valid phone number, 10 numbers. Example: 8013650394"
            />
            {isCarta && (
              <InputGroup
                required={isCarta?true:false}
                label="Date of Birth:"
                labelFor="dateOfBirth"
                dataId={"update-card-date-of-birth-input"}
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                pattern="^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-[0-9]{4}$"
                title="Valid Date, DD-MM-YYYY"
              />
            )}
          </Row>
        </FormSection>
        {
          !isVirtual && (
            <FormSection>
            <FormSectionTitle>Shipping Information</FormSectionTitle>
            <Row>
                <Checkbox
                  label=" Same As Billing Information"
                  labelFor="sameAsBillingAddress"
                  id="update-card-same-as-billing-address-input"
                  value={isSameAsBilling}
                  onChange={(e) => setIsSameAsBilling(!isSameAsBilling)}
                />
            </Row>
            {!isSameAsBilling && (
              <>
              {
                isCarta && (
                <Row>
                  <InputGroup
                    required
                    label="First Name:"
                    labelFor="firstNameShipping"
                    dataId={"update-card-first-name-shipping-input"}
                    value={firstNameShipping}
                    onChange={(e) => setFirstNameShipping(e.target.value)}
                    pattern="^[A-Za-z\-.\s']{1,40}$"
                    title="Input must contain 1-40 characters; letters, dashes, periods, spaces and apostrophes.
                    only." 
                  />
                  <InputGroup
                    required
                    label="Last Name:"
                    labelFor="lastNameShipping"
                    dataId={"update-card-middle-name-shipping-input"}
                    value={lastNameShipping}
                    onChange={(e) => setLastNameShipping(e.target.value)}
                    pattern="^[A-Za-z\-.\s']{1,40}$"
                    title="Input must contain 1-40 characters; letters, dashes, periods, spaces and apostrophes.
                    only."
                  />
                </Row>
                )
              }
                <Row>
                  <InputGroup
                    required
                    label="Address line 1:"
                    labelFor="addressOneShipping"
                    dataId={"update-card-address-one-shipping-input"}
                    value={addressOneShipping}
                    onChange={(e) => setAddressOneShipping(e.target.value)}
                    pattern="^.{1,40}$"
                    title="Maximum length of 40 characters. Cannot be a P.O. Box."
                  />   
                  <InputGroup
                    label="Address line 2:"
                    labelFor="addressTwoShipping"
                    dataId={"update-card-address-two-shipping-input"}
                    value={addressTwoShipping}
                    onChange={(e) => setAddressTwoShipping(e.target.value)}
                    pattern="^.{1,30}$"
                    title="Maximum length of 30 characters."
                  />
                </Row>
                <Row>
                  <InputGroup
                    required
                    label="City:"
                    labelFor="cityShipping"
                    dataId={"update-card-city-shipping-input"}
                    value={cityShipping}
                    onChange={(e) => setCityShipping(e.target.value)}
                    pattern="^[a-zA-Z ]{1,20}$"
                    title="Letters and spaces, maximum length of 20 characters."
                  />
                  <InputGroup
                    required
                    label={isCanada? "Province:":"State:"}
                    labelFor="provinceShipping"
                    dataId={"update-card-province-shipping-input"}
                    value={provinceShipping}
                    onChange={(e) => setProvinceShipping(e.target.value)}
                    pattern={isCanada?"^(AB|BC|MB|NB|NL|NS|NT|NU|ON|PE|QC|SK|YT)$":"^(AL|AK|AZ|AR|CA|CO|CT|DE|DC|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY)$"}
                    title="Valid 2 character state abbreviation."
                  />
                  <InputGroup
                    required
                    label="Country:"
                    labelFor="countryShipping"
                    dataId={"update-card-country-shipping-input"}
                    value={countryShipping}
                    onChange={(e) => setCountryShipping(e.target.value)}
                    pattern="^(CA|US)$"
                    title="Valid 2 character country abbreviation."
                  />
                  <InputGroup
                    required
                    label={isCanada? "Postal Code:":"Zip Code:"}
                    labelFor="postalCodeShipping"
                    dataId={"update-card-postal-code-shipping-input"}
                    value={postalCodeShipping}
                    onChange={(e) => setPostalCodeShipping(e.target.value)}
                    pattern="^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)?$"
                    title="Valid input examples: 12345 or 12345-1234 (US), M5V-2M7 or M5V2M7 (CA)."
                  />
                </Row>
              </>
            )}
          </FormSection>
          )
        }
        <ActionBar>
          <ButtonGroup reverse justifyContent="space-between">
            <SubmitButton
              size="medium"
              buttonType="primary"
              type="submit"
            />
          </ButtonGroup>
        </ActionBar>
      </FormGroup>
    </>
  )
}

export default UpdateCardForm