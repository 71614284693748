import Papa from 'papaparse';
import { validateName, validateNonZeroLoadAmount, validateCity, validateNumbersOnly, validateShippingTo, validatePostalCode, validateProvince, validateCountry } from 'shared-components/src/validations/cardholderValidations';
import {
  validateAccountId,
  validateExternalTag,
  validateMessage,
} from 'shared-components/src/validations/valueLoadValidations';
import {
  validateLastFour,
  validateLongExpiryYear,
  validateExpiryMonth,
  validateCVV,
  validateModifyCardStatus,
} from 'shared-components/src/validations/cardValidations';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
import { formatCurrencyCSV } from 'shared-components/src/utils/formatCurrency';
import { removeSnakeCase } from 'shared-components/src/utils/string';
import { stringToIsoCountryCodeList } from 'shared-components/src/utils/countries';
import {
  parseAmountToCents,
  centsToDollars,
} from 'shared-components/src/utils/currency';
// Import helper function for determining field requirements of cardholder CSV
import { getCSVFieldDetails } from '../routes/CardIssuing/CreateCardholders/components/CSVFieldDetails/fieldData';
import { isVirtualProgram } from '../utils/programs';
import { validateAddressLine1 } from 'shared-components/src/validations/cardholderValidations';
import { validateAddressLine2 } from 'shared-components/src/validations/cardholderValidations';

// Max number of allowable elements in file
// This is currently capped at 10,000
export const FILE_UPLOAD_LENGTH_LIMIT = 10000;

// Date to be attached to csv filename
export const fileDate = new Date().toISOString().split('T')[0];

// CSV parser utils

// Promisified CSV parser
export const parse = file =>
  new Promise((resolve, reject) =>
    Papa.parse(file, {
      // eslint-disable-next-line no-unused-vars
      complete: (result, file) => resolve(result),
      // eslint-disable-next-line no-unused-vars
      error: (error, file) => reject(error),
      skipEmptyLines: true,
    })
  );

// -------------------
// | ACCOUNT HOLDERS |
// -------------------
export const getAccountHolderCSVData = accountHolders =>
  // Transform accountHolder data to an array of object literals. Each item is
  // rendered as a CSV line and will be ordered as defined by the headers.
  accountHolders.map(accountHolder => ({
    name: accountHolder.contactDetails.fullName,
    type: accountHolder.type,
    email: accountHolder.email,
    phone: accountHolder.contactDetails.phone,
  }));

export const getCardholderCSVData = cardholders =>
  cardholders.map(cardholder => ({
    id: cardholder.id,
    username: cardholder.username,
    companyId: cardholder.company_id,
    primaryProcessorReference: cardholder.primary_processor_reference,
    externalTag: cardholder.external_tag,
    createdAt: cardholder.created_at,
    officeId: cardholder.office_id,
  }));

// -------------
// | REPORTING |
// -------------
export const getReportsCsvData = (transactions, timeZone) =>
  // Transform transaction data to an array of object literals. Each item is
  // rendered as a CSV line and will be ordered as defined by the headers.
  transactions.map(transaction => {
    const date = formatTime(`${transaction.insertedAt}Z`, 'dateTime', timeZone);
    const amount = formatCurrencyCSV(transaction.amount / 100, transaction.currency);
    const status = removeSnakeCase(transaction.latestStatus);

    return {
      date,
      company: transaction.company.name,
      name: transaction.financialAccount.accountHolder.contactDetails.fullName,
      email: transaction.financialAccount.accountHolder.email,
      amount,
      type: transaction.type,
      status,
    };
  });

// -------------
// | TRANSFERS |
// -------------
export const getTransfersCsvData = (transfers, timeZone) =>
  // Transform transfer data to an array of object literals. Each item is
  // rendered as a CSV line and will be ordered as defined by the headers.
  transfers.map(transfer => {
    const date = formatTime(`${transfer.insertedAt}Z`, 'dateTime', timeZone);
    const payments = transfer.recipientsCount;
    const totalPaymentsAmount = transfer.amount;
    const currency =
      transfer.currency != null
        ? transfer.currency
        : 'CAD';
    const amount = formatCurrencyCSV(totalPaymentsAmount / 100, currency);
    const expiry = formatTime(`${transfer.expirationDatetime}Z`, 'date', timeZone);
    const status = removeSnakeCase(transfer.latestStatus);
    return {
      date,
      name: transfer.name,
      payments,
      amount,
      expiry,
      status,
    };
  });

// ---------------
// | COLLECTIONS |
// ---------------
export const getCollectionsCsvData = (collections, timeZone) =>
  // Transform transfer data to an array of object literals. Each item is
  // rendered as a CSV line and will be ordered as defined by the headers.
  collections.map(collection => {
    return {
      date: formatTime(collection.created_at, 'dateTime', timeZone),
      name: `${collection.first_name} ${collection.last_name}`,
      amount: formatCurrencyCSV(collection.amount / 100, collection.currency),
      externalTag: collection.external_tag,
      expiry: formatTime(collection.expiry, 'date', timeZone),
      status: removeSnakeCase(collection.status),
    };
  });

// ----------------------
// | COLLECTION REPORTS |
// ----------------------
export const getCollectionsReportCsvData = transactions =>
  // Transform transfer data to an array of object literals. Each item is
  // rendered as a CSV line and will be ordered as defined by the headers.
  transactions.map(transaction => {
    return {
      collection_email: transaction.collection_email,
      collection_account_ref: transaction.collection_account_ref,
      collection_external_tag: transaction.collection_external_tag,
      collection_redemption_token: transaction.collection_redemption_token,
      collection_name: transaction.collection_first_name + ' ' + transaction.collection_last_name,
      transaction_id: transaction.id,
      transaction_time: transaction.updated_at,
      transaction_status: transaction.status,
      transaction_type: transaction.type,
      transaction_amount: formatCurrencyCSV(transaction.amount / 100, transaction.currency),
      processor_transaction_id: transaction.processor_id,
      processor_transaction_time: transaction.processor_time,
      processor_result_message: transaction.processor_result_message,
      authorization_code: transaction.authorization_code,
      avs_response: transaction.avs_result,
      cvv_response: transaction.cvv_result,
      card_first_name: transaction.cardholder_first_name,
      card_last_name: transaction.cardholder_last_name,
      card_type: transaction.card_type,
      card_last_four: transaction.card_last_four,
      card_exp_date: transaction.expiry_date,
    };
  });

// ----------------------------
// ****Parse Cardholder CSV****
// ----------------------------

// Function to calculate uploaded CSV cardholder details
export function getCardholderDetails(data) {
  const amount = centsToDollars(
    data.filter(x => x.load_amount).reduce((x, y) => x + y.load_amount, 0)
  );

  return {
    cardholders: data.length,
    amount,
  };
}

// all csv field names for creating a cardholder
const csvFieldNames = [
  'external_tag',
  'first_name',
  'last_name',
  'email',
  'phone',
  'date_of_birth',
  'address1',
  'address2',
  'city',
  'state',
  'postal_code',
  'country',
  'shipping_method',
  'load_amount',
  'emboss_line2',
  'sin',
  'shipping_first_name',
  'shipping_last_name',
  'shipping_address1',
  'shipping_address2',
  'shipping_city',
  'shipping_state',
  'shipping_postal_code',
  'shipping_country_code',
  'location_id',
  'office_id',
  'employer_name',
  'occupation',
  'non_resident_alien',
  'politically_exposed_person',
  'primary_source_of_funds',
  'primary_use_of_account',
  'country_of_citizenship',
];

// Fields required only when a program is a physical card program
export const physicalOnlyFields = [
  'shipping_method',
  'shipping_first_name',
  'shipping_last_name',
  'shipping_address1',
  'shipping_address2',
  'shipping_city',
  'shipping_state',
  'shipping_postal_code',
  'shipping_country_code',
];

export const allPhysicalOnlyFields = [
  ...physicalOnlyFields,
  'location_id'
]

// Fields required only when a program is a physical card program from Galileo
export const galileoPhysicalOnlyFields = [
  'location_id',
];

export const shippingFields = [
  'shipping_first_name',
  'shipping_last_name',
  'shipping_address1',
  'shipping_address2',
  'shipping_city',
  'shipping_state',
  'shipping_postal_code',
  'shipping_country_code',
];

// Formats the shipping info under one key
function formatCardholderShippingFields(
  {
    shipping_first_name,
    shipping_last_name,
    shipping_address1,
    shipping_address2,
    shipping_city,
    shipping_state,
    shipping_postal_code,
    shipping_country_code,
    ...nonShippingFields
  },
  shippingInfoFilled
) {
  const shipping_address = {
    first_name: shipping_first_name,
    last_name: shipping_last_name,
    address1: shipping_address1,
    address2: shipping_address2,
    city: shipping_city,
    state: shipping_state,
    postal_code: shipping_postal_code,
    country: shipping_country_code,
  };

  // Only return shipping_address if filled in
  return shippingInfoFilled
    ? { ...nonShippingFields, shipping_address }
    : nonShippingFields;
}

// Validates the information is not empty.
// We return an error if it is
// We handle field validation through a validation endpoint
function validateNotEmpty(id, data, column = '') {
  if (!(data?.trim() && data.trim() !== '')) {
    // Add column info to error if passed in
    const errorString = `Missing ${column} at row ${id + 2} of uploaded CSV`;
    throw new Error(errorString);
  }
}

export function parseCardRows(row, id, program) {
  const csvRequirement = getCSVFieldDetails(program);
  const isVirtual = isVirtualProgram(program);

  // remove shipping related info if virtual
  const csvFieldsToValidate = isVirtual
    ? csvFieldNames.filter(field => !allPhysicalOnlyFields.includes(field))
    : program.processor_name !== 'galileo'
        ? csvFieldNames.filter(field => !galileoPhysicalOnlyFields.includes(field))
        : csvFieldNames;

  // Checks if any one of the shipping address info has been filled
  const isShippingInfoFilled = isVirtual
    ? false
    : row
        .slice(16)
        .some(shippingInfo => shippingInfo.trim() !== '');

  // We are only checking if a required field is empty or not
  // We error is a field is empty that should have a value
  const csvFieldValues = csvFieldsToValidate
    .map((field, i) => {
      if (csvRequirement[i].required === 'Yes') {
        validateNotEmpty(id, row[i], csvRequirement[i].field);
      } else if (csvRequirement[i].field.includes('Shipping Address')) {
        // Validates shipping address separately because shipping address is only required
        // if anyone of the shipping address is filled
        // Shipping Address Line2 is always non required
        if (isShippingInfoFilled && field !== 'shipping_address2') {
          validateNotEmpty(id, row[i], csvRequirement[i].field);
        }
      }
      return field;
    })
    .reduce((acc, field, i) => {
      // These fields need to be converted before sent to cardholder creation
      let fieldValue = row[i];
      if (field === 'country') {
        fieldValue = stringToIsoCountryCodeList[row[i]];
      }
      if (field === 'load_amount') {
        fieldValue = convertAmount(row[i]);
      }
      if (field === 'shipping_country_code') {
        fieldValue = stringToIsoCountryCodeList[row[i]];
      }

      if (field === 'non_resident_alien' || field === 'politically_exposed_person') {
        if (!fieldValue || fieldValue.trim() === '') {
          fieldValue = null;
        } else {
          fieldValue = row[i] === 'Y' ? true : row[i] === 'N' ? false : (() => {
            throw new Error(`Invalid value for ${field} at row ${id + 2}: must be 'Y' or 'N'`);
          })();
        }
      }

      if (field === 'primary_source_of_funds' && row[i] !== '') {
        if (!fieldValue || fieldValue.trim() === '') {
          fieldValue = null;
        } else {
          fieldValue = ['Paycheck', 'Government Benefits', 'Retirement', 'Cash', 'Other'].includes(row[i]) ? row[i] : (() => {
            throw new Error(`Invalid value for ${field} at row ${id + 2}: must be one of "Paycheck", "Government Benefits", "Retirement", "Cash", "Other"`);
          })();
        }
      }

      if (field === 'primary_use_of_account' && row[i] !== '')  {
        if (!fieldValue || fieldValue.trim() === '') {
          fieldValue = null;
        } else {
          fieldValue = ['Household Expenses', 'Investment', 'Savings', 'Other'].includes(row[i]) ? row[i] : (() => {
            throw new Error(`Invalid value for ${field} at row ${id + 2}: must be one of "Household Expenses", "Investment", "Savings", "Other"`);
          })();
        }
      }

      if (field === 'country_of_citizenship' || field === 'employer_name' || field === 'occupation') {
        if (!fieldValue || fieldValue.trim() === '') {
          fieldValue = null;
        }
      }
      
      // Convert office_id to integer with validation or null if blank
      if (field === 'office_id') {
        if (!fieldValue || fieldValue.trim() === '') {
          // If blank or empty, set to null
          fieldValue = null;
        } else {
          cardValidateNonRequired(id, fieldValue, validateNumbersOnly, 'office_id');
          
          // // Parse to integer
          fieldValue = parseInt(fieldValue.trim(), 10);
          
          if (fieldValue <= 0) {
            throw new Error(`Invalid office_id at row ${id + 2}: must be a positive number`);
          }
        }
      }
      
      acc[field] = fieldValue;
      return acc;
    }, {});

  // formats the shipping info under one key and return the object needed for submission to create a cardholder
  return formatCardholderShippingFields(csvFieldValues, isShippingInfoFilled);
}

export function parseCreateCardholderCardRows(rows, program) {
  return rows.map((row, id) =>
    parseCardRows(row, id, program)
  );
}

// ----------------------------
// ****Parse Value Load CSV****
// ----------------------------

function validateRequired(id, data, validator, column = '') {
  if (!(data?.trim() && validator(data))) {
    // Add column info to error if passed in
    const errorString = `Missing or invalid information: Row: ${++id}, Data: ${data}`;
    const columnString = `, Column: ${column}`;
    throw new Error(errorString + columnString);
  }
}

function validateNonRequired(id, data, validator, column = '') {
  if (data?.trim() && !validator(data)) {
    const errorString = `Invalid information entered: Row: ${++id}, Data: ${data}`;
    const columnString = `, Column: ${column}`;
    throw new Error(errorString + columnString);
  }
}

export function parseValueLoadRows(row, id) {
  // validate card proxy number
  const account_id = row[0];
  validateRequired(id, account_id, validateAccountId);
  // validate externalTag
  const external_tag = row[1];
  validateNonRequired(id, external_tag, validateExternalTag);
  // validate amount
  const loadAmount = row[2];
  validateRequired(id, loadAmount, validateNonZeroLoadAmount);
  // validate note
  const message = row[3];
  validateNonRequired(id, message, validateMessage);

  return {
    account_id: parseInt(account_id, 10),
    external_tag,
    amount: parseAmountToCents(loadAmount),
    message,
  };
}

function convertAmount(data) {
  return data.length >= 1 ? parseAmountToCents(data) : null;
}

// --------------------------------
// ****Parse Activate Cards CSV****
// --------------------------------

function cardValidateRequired(id, data, validator, column = '') {
  if (!(data?.trim() && validator(data))) {
    // Add column info to error if passed in
    const errorString = `Missing or invalid information: Row: ${++id}, Data: ${data}`;
    const columnString = `, Column: ${column}`;
    throw new Error(errorString + columnString);
  }
}

function cardValidateNonRequired(id, data, validator, column = '') {
  if (data?.trim() && !validator(data)) {
    const errorString = `Invalid information entered: Row: ${++id}, Data: ${data}`;
    const columnString = `, Column: ${column}`;
    throw new Error(errorString + columnString);
  }
}

// Function to calculate uploaded CSV card details
export function getCardDetails(data) {
  return {
    cards: data.length
  };
}

export function parseActivateCards(row, id) {
  // validate account id
  const account_id = row[0];
  cardValidateRequired(id, account_id, validateAccountId, 'account_id');
  // validate last four digits
  const last_four_digits = row[1];
  cardValidateRequired(id, last_four_digits, validateLastFour, 'last_four_digits');
    // validate expiry year
    const expiry_year = row[2];
    cardValidateRequired(id, expiry_year, validateLongExpiryYear, 'expiry_year');
  // validate expiry month
  const expiry_month = row[3];
  cardValidateRequired(id, expiry_month, validateExpiryMonth, 'expiry_month');
  // validate CVV
  const cvv = row[4];
  cardValidateNonRequired(id, cvv, validateCVV, 'cvv');
  return {
    account_id,
    last_four_digits,
    expiry_month,
    expiry_year,
    cvv
  };
}

export function parseCloseUnloads(row, row_id) {
  // validate account id (proxy )
  const id = row[0];
  cardValidateRequired(row_id, id, validateAccountId, 'id');
  const message = row[1];
  cardValidateNonRequired(row_id, message, validateMessage, 'message')
  const external_tag = row[2];
  cardValidateNonRequired(row_id, external_tag, validateExternalTag, 'external_tag')
  return {
    id,
    message,
    external_tag
  };
}

// Function to validate modify cards 
export function parseModifyCardStatus(row, id) {
  // validate account id
  const account_id = row[0];
  cardValidateRequired(id, account_id, validateAccountId, 'account_id');
  // validate last four digits
  const last_four_digits = row[1];
  cardValidateRequired(id, last_four_digits, validateLastFour, 'last_four_digits');
  // validate status
  const status = row[2];
  cardValidateRequired(id, status, validateModifyCardStatus, 'status');
  return {
    account_id,
    last_four_digits,
    status
  };
}

// Function to validate Instant Issuance card creation
export function parseInstantIssuanceCreateCards(row, id) {
  // validate row id
  const row_id = row[0];
  cardValidateRequired(id, row_id, validateNumbersOnly, 'id');
  // validate qty
  const qty = row[1];
  cardValidateRequired(id, qty, validateNumbersOnly, 'qty');
  // validate first name
  const first_name = row[2];
  cardValidateRequired(id, first_name, validateName, 'first_name');
  // validate last name
  const last_name = row[3];
  cardValidateRequired(id, last_name, validateName, 'last_name');
  const fourth_line_embossing = row[4];
  // validate address line 1
  const address_line_1 = row[5];
  cardValidateRequired(id, address_line_1, validateAddressLine1, 'address_line_1');
  // validate address line 2
  const address_line_2 = row[6];
  cardValidateNonRequired(id, address_line_2, validateAddressLine2, 'address_line_2');
  // validate city
  const city = row[7];
  cardValidateRequired(id, city, validateCity, 'city');
  // validate province/state
  const province_state = row[8];
  cardValidateRequired(id, province_state, validateProvince, 'province_state');
  // validate country
  const country = row[9];
  cardValidateRequired(id, country, validateCountry, 'country');
  // validate post/zip code
  const post_zip_code = row[10];
  cardValidateRequired(id, post_zip_code, validatePostalCode, 'post_zip_code');
  // validate shipping address
  const shipping_to = row[11];
  cardValidateRequired(id, shipping_to, validateShippingTo, 'shipping_to');
  return {
    row_id,
    qty,
    first_name,
    last_name,
    fourth_line_embossing,
    address_line_1,
    address_line_2,
    city,
    province_state,
    country,
    post_zip_code,
    shipping_to
  };
}