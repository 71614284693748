import PropTypes from 'prop-types';

// File contains common patterns of propTypes across fe-monorepo

export const card = PropTypes.shape({
  expiry_date: PropTypes.string.isRequired,
  last_four_digits: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
});

export const cards = PropTypes.arrayOf(card);

export const csvCardInput = PropTypes.shape({
  account_id: PropTypes.number.isRequired,
  last_four_digits: PropTypes.string.isRequired,
  expiry_year: PropTypes.string.isRequired,
  expiry_month: PropTypes.string.isRequired,
  cvv: PropTypes.string,
});

export const csvCardInputs = PropTypes.arrayOf(csvCardInput);

export const account = PropTypes.shape({
  cardholder_id: PropTypes.number.isRequired,
  cards: cards.isRequired,
  created_at: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  processor_reference: PropTypes.string.isRequired,
  program_id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  status_code: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
});

export const accounts = PropTypes.arrayOf(account);

export const csvCardholderInput = PropTypes.shape({
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  city: PropTypes.string.isRequired,
  company_id: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  date_of_birth: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  emboss_line2: PropTypes.string,
  external_tag: PropTypes.string,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  load_amount: PropTypes.number,
  phone: PropTypes.string,
  postal_code: PropTypes.string.isRequired,
  primary_processor_reference: PropTypes.string.isRequired,
  shipping_address: PropTypes.shape({
    shipping_address1: PropTypes.string,
    shipping_address2: PropTypes.string,
    shipping_city: PropTypes.string,
    shipping_country: PropTypes.string,
    shipping_postal_code: PropTypes.string,
    shipping_state: PropTypes.string,
  }),
  shipping_method: PropTypes.string,
  state: PropTypes.string.isRequired,
});

export const csvCardholderInputs = PropTypes.arrayOf(csvCardholderInput);

export const cardholder = PropTypes.shape({
  company_id: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  external_tag: PropTypes.string,
  id: PropTypes.number.isRequired,
  primary_processor_reference: PropTypes.string.isRequired,
  username: PropTypes.string,
});

export const cardholders = PropTypes.arrayOf(cardholder);

export const cardholderOverview = PropTypes.shape({
  accounts: accounts.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  city: PropTypes.string.isRequired,
  company_id: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  date_of_birth: PropTypes.string,
  email: PropTypes.string.isRequired,
  external_tag: PropTypes.string,
  first_name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  last_name: PropTypes.string.isRequired,
  middle_name: PropTypes.string.isRequired,
  phone: PropTypes.string,
  postal_code: PropTypes.string.isRequired,
  primary_processor_reference: PropTypes.string.isRequired,
  shipping_method: PropTypes.string,
  state: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  username: PropTypes.string,
});

export const personToPersonAccount = PropTypes.shape({
  created_at: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  updated_at: PropTypes.string,
});

export const program = PropTypes.shape({
  card_printer_package_id: PropTypes.number,
  company_id: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  direct_deposit_account: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  is_GRP: PropTypes.bool.isRequired,
  master_funding_account_number: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  processor_id: PropTypes.number.isRequired,
  processor_name: PropTypes.string.isRequired,
  processor_program_id: PropTypes.number.isRequired,
  processor_reference: PropTypes.bool.isRequired,
  program_type: PropTypes.string.isRequired,
  requires_kyc_check: PropTypes.bool.isRequired,
  requires_sin_verification: PropTypes.bool.isRequired,
  require_addl_due_diligence: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
});

export const programs = PropTypes.arrayOf(program);

export const fieldError = PropTypes.shape({
  code: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  field_errors: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      message: PropTypes.string,
      position: PropTypes.arrayOf(PropTypes.string),
      invalid_values: PropTypes.arrayOf(PropTypes.string),
      valid_values: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          description: PropTypes.string,
        })
      ),
      value: PropTypes.string,
    })
  ).isRequired,
});
