import React, { useState } from 'react';
// Import Shared Components
import {
  Title,
  InputGroup,
  SubmitButton,
  ActionBar,
  Alert,
} from 'shared-components';
// Import Shared Hook
import { useUpdateStateObject } from 'shared-components/src/hooks/useUpdateStateObject';
// Import Global Utils
import {
  reportFrequency,
  reportTimeRange,
  checkForValidationErrors,
  runValidationCheck,
  validateReportEmail,
} from '../../../../utils/reports';
import { post } from '../../../../utils/http';
// Import Local Components
import {
  EmailTooltip,
  FrequencyTooltip,
  CartaFrequencyTooltip,
  TimeRangeTooltip,
  ReportingMethodTooltip,
} from './ReportTooltips';
// Import Component Styles
import { Form, InputsContainer, Row } from '../styles';

/* eslint react/prop-types: 0 */
const ReportSubscriptionForm = ({
  reportInfo,
  setLoading,
  history,
  program,
  reportTypeOptions,
  setReportInfo,
  submitError,
  setSubmitError,
  reportingMethodOptions,
}) => {
  const initialValidationErrors = {
    reportType: false,
    frequency: false,
    timeRange: false,
    endDate: false,
    email: false,
  };
  const [formError, setFormError] = useState(null);
  const [validationErrors, setValidationErrors] = useUpdateStateObject(
    initialValidationErrors
  );

  const [emailRequired, setEmailRequired] = useState(false);

  const isCarta = program.processor_name === 'carta' || program.processor_name === 'e6';

  // Call to create report subscription
  const createReportSubscription = async payload => {
    try {
      setLoading(true);
      await post(
        `/api/v1/client_portal/card_issuing/programs/${reportInfo.programID}/reporting`,
        payload
      );
      setLoading(false);
      history.push({
        pathname: `/card-issuing/programs/${reportInfo.programID}`,
        state: { success: 'Report Subscription was successfully created.' },
      });
    } catch (e) {
      setLoading(false);
      setSubmitError(e.message);
    }
  };

  // Handle update of dropdown inputs
  const handleUpdateItem = (key, value) => {
    setReportInfo({
      key,
      value,
    });
    setValidationErrors({ key, value: false });
  };

  // Handle submission of reporting form
  const handleSubmit = () => {
    setSubmitError(false);
    setFormError(false);

    if (checkForValidationErrors(reportInfo))
      return setFormError(
        'Form contains invalid or blank inputs, please correct them before submitting'
      );

    const payload = {
      program_id: reportInfo.programID,
      report_subscription: {
        email: reportInfo.email,
        report: reportInfo.reportType,
        frequency: reportInfo.frequency,
        time_range: reportInfo.timeRange,
        reporting_method: reportInfo.reportingMethod,
      },
    };

    return createReportSubscription(payload);
  };

  const handleUpdateReportingMethod = (key, value) => {
    setReportInfo({
      key,
      value,
    });
    setValidationErrors({ key, value: false });

    value === 'email' || value === 'email_and_sftp' ? setEmailRequired(true) : setEmailRequired(false);
  };
  return (
    <>
      <Title data-id="reporting-title">Report Subscription Form</Title>
      {submitError && <Alert type="danger">{submitError}</Alert>}
      <Form
        data-id="reporting-form"
        onSubmit={e => {
          e.preventDefault();
          runValidationCheck(setValidationErrors, reportInfo);
          return handleSubmit();
        }}
      >
        <InputGroup
          dataId="reporting-form-dropdown-program"
          value={program.name}
          caption="Select Program..."
          labelFor="reporting-form-program"
          label="Program:"
          name="program"
          disabled
          required
        />
        {reportInfo.programID && (
          <>
            <InputsContainer>
              <Row>
                <InputGroup
                  dataId="reporting-form-dropdown-report-type"
                  dropdown
                  options={reportTypeOptions}
                  selected={reportInfo.reportType}
                  updateItem={value => handleUpdateItem('reportType', value)}
                  error={validationErrors.reportType}
                  caption="Select Report..."
                  labelFor="reporting-form-report-type"
                  label="Report Type:"
                  required
                />
                <InputGroup
                  dataId="reporting-form-input-email"
                  labelFor="reporting-form-email"
                  label="Email:"
                  value={reportInfo.email}
                  onChange={e => {
                    if (e.target.value.trim() === '') {
                      setReportInfo({
                        key: 'email',
                        value: null,
                      });
                    } else {
                      setReportInfo({
                        key: 'email',
                        value: e.target.value.trim(),
                      });
                    }
                  }}
                  onBlur={() =>
                    validateReportEmail(setValidationErrors, reportInfo)}
                  error={validationErrors.email}
                  required={emailRequired}
                  tooltip={<EmailTooltip />}
                />
              </Row>
              <Row>
                <InputGroup
                  dataId="reporting-form-dropdown-report-type"
                  dropdown
                  options={reportFrequency}
                  selected={reportInfo.frequency}
                  updateItem={value => handleUpdateItem('frequency', value)}
                  error={validationErrors.frequency}
                  caption="Select Frequency..."
                  labelFor="reporting-form-report-frequency"
                  label="Report Frequency:"
                  required
                  tooltip={isCarta ? <CartaFrequencyTooltip /> : <FrequencyTooltip />}
                />
                <InputGroup
                  dataId="reporting-form-dropdown-reporting-method"
                  dropdown
                  options={reportingMethodOptions}
                  selected={reportInfo.reportingMethod}
                  updateItem={value =>
                    handleUpdateReportingMethod('reportingMethod', value)}
                  error={validationErrors.frequency}
                  caption="Select how to receive report..."
                  labelFor="reporting-form-reporting-method"
                  label="Reporting Method:"
                  required
                  tooltip={<ReportingMethodTooltip />}
                />
                <InputGroup
                  dropdown
                  dataId="reporting-form-input-time-range"
                  labelFor="reporting-form-time-range"
                  label="Time Range:"
                  options={reportTimeRange}
                  selected={reportInfo.timeRange}
                  updateItem={value => handleUpdateItem('timeRange', value)}
                  error={validationErrors.timeRange}
                  tooltip={<TimeRangeTooltip />}
                  required
                />
              </Row>
            </InputsContainer>
            {formError && (
              <Alert data-id="reporting-form-error-alert" type="danger">
                {formError}
              </Alert>
            )}
            <ActionBar>
              <SubmitButton
                data-id="reporting-form-submit-button"
                buttonType="primary"
                value="Create Report Subscription"
              />
            </ActionBar>
          </>
        )}
      </Form>
    </>
  );
};

export default ReportSubscriptionForm;